import React from "react";
import QRCode from "react-qr-code";
import { useParams } from 'react-router-dom';
import './qrcode.css'


const Qrcode = () => {
    const { id } = useParams<string>();

    return (
        <div className="container">
            <div className="qrbox">
                {
                    id != undefined ? <QRCode value={id} /> : null
                }
                <p>{id}</p>
            </div>
        </div>
    )
}

export default Qrcode;